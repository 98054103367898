import React, {Component} from 'react';
//import navigation from '../js/views.js';
import {logout} from '../js/communication.js';
import './HeaderBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class HeaderBar extends Component{

  handleLogout(e){
    e.preventDefault();
    logout(function(err, success){
      if(err || !success){
        console.log("Logout failed:",err);
        return;
      }
      window.location.reload();
    });
  }

  courseListButton(){
    return (<div className="headerbarButton" onClick={this.props.courseListView}><FontAwesomeIcon icon="home"/></div>);
  }

  logoutButton(){
    return (<div className="headerbarButton" onClick= {this.handleLogout}><FontAwesomeIcon icon="sign-out-alt"/></div>);
  }

  render(){
    return(
      <div className="sticky headerBar" id="header">
      {this.courseListButton()}
      ViLLE
      {this.logoutButton()}
      </div>
    );
  }
}
export default HeaderBar;
