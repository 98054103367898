import React, {Component} from 'react';
import LoadingIcon from '../LoadingIcon.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './AttendanceView.css';
import * as server from '../../js/communication.js';

class AttendanceView extends Component{

  constructor(props){
    super(props);
    this.state = {loading : true, attendances : null};

    var that = this;
    server.getAttendanceAssigs(props.data.courseId, function(err, courseDetails){
      if(err){
        return;
      }

      that.setState({
        loading : false,
        data : courseDetails
        });
    });

  }

  handleAssigClick(assigId){
    var that = this;
    server.getCourseAttendance(this.props.data.courseId, assigId, function(err, courseDetails){
      if(err){
        console.log(err);
        return;
      }
      that.setState({
        loadingUsers : false,
        attendances : JSON.parse(courseDetails),
        assigId : assigId
        });
    });
    that.setState({
      loadingUsers : true,
      attendances : null,
      assigId : null
      });
  }

  getAssigItems(assigArr){
    var items = [];
    for(var i in assigArr){
      var item = assigArr[i];
      items.push(
        <div key={item.aid} onClick={this.handleAssigClick.bind(this,item.aid)}>
        {item.aname}
        </div>);
    }
    return items;
  }

  handleRoundClick(roundid){
    if(this.state.AssigsShown === roundid){
        this.setState({AssigsShown : null});
    }else{
      this.setState({AssigsShown : roundid});
    }
  }
  getAttendanceView(){
    var details = this.state.data;
    var data = {}
    for(let i in details){
      let item = details[i];
      if(!data[item.rid]){
        data[item.rid] = [];
      }
      data[item.rid].push({aname : item.aname, aid : item.aid, rname : item.rname});
    }

    var items = [];
    for (let i in data){
      let item = data[i];
      items.push(
        <div key ={i} className="attendanceRoundItem">
          <div onClick={this.handleRoundClick.bind(this,i)}>{item[0].rname}</div>
          <div className="attendanceRoundAssigs" style= {{display:this.state.AssigsShown ===i ? "block" : "none"}}>
            {this.getAssigItems(item)}
          </div>
        </div>);
    }
    return items
  }

  getUserAttendanceList(){
    if(this.state.loadingUsers === true){
      return <LoadingIcon/>;
    }

    if(this.state.attendances === null)
      return null;

      var items = [];

      for(var i in this.state.attendances){
        var item = this.state.attendances[i];
        var style = "attendanceUserItem "+ (i%2===0 ? "" : "darker");
        var icon = item.score == 0 ?   <FontAwesomeIcon icon={["far","square"]}/> : <FontAwesomeIcon icon={["far","check-square"]}/>  ;
        items.push(
          <AttendanceRow
          key={i}
          style={style}
          name={item.name}
          attendance={item.score!=0}
          assigId={this.state.assigId}
          courseId={this.props.data.courseId}
          />
          /*<div key={i} className={style}>
            <div className="attendanceUserName">{item.name}</div>
            <div className="attendanceUserCheckbox">{icon}</div>
          </div>*/
        );
      }
      return items;
  }

  render(){
    var page = <LoadingIcon/>;
    if(!this.state.loading){
      page = this.getAttendanceView();
    }
    return(
      <div className="attendanceView">
        <div className="attendanceRoundList">
        {page}
        </div>
        <div className="attendanceUserList">
        {this.getUserAttendanceList()}
        </div>
      </div>
    );
  }
}

export default AttendanceView;

class AttendanceRow extends Component{

  constructor(props){
    super(props);
    this.props = props;
    this.state = {attendance : props.attendance};
  }

  updateAttendanceStatus(){
    var that = this;
    var attendance = this.state.attendance;
    server.putAttendance(this.props.courseId, this.props.assigId, !attendance, function(err, resut){
      if(err){
        console.log("err",err);
        return;
      }
      that.setState({attendance : !attendance});
    });
  }

  render(){
    var icon = this.state.attendance ? <FontAwesomeIcon icon={["far","check-square"]}/> : <FontAwesomeIcon icon={["far","square"]}/>  ;

    return(
      <div className={this.props.style} onClick={this.updateAttendanceStatus.bind(this)}>
        <div className="attendanceUserName">{this.props.name}</div>
        <div className="attendanceUserCheckbox">{icon}</div>
      </div>

    );
  }
}
