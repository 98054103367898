/**
 * Created by Juhani 21/05/2015
 */
export function getVUID() {
  if(sessionStorage.getItem("VUID") == null) {
    sessionStorage.setItem("VUID",createVUID());
  }

  return sessionStorage.getItem("VUID");
}
function createVUID() {
    return VUIDcreatePart(4) + '-' +
        VUIDcreatePart(2) + '-' +
        VUIDcreatePart(2) + '-' +
        VUIDcreatePart(2) + '-' +
        VUIDcreatePart(6);
}
function VUIDcreatePart(length) {
    var uuidpart = "";
    for (var i=0; i<length; i++) {
        var uuidchar = parseInt((Math.random() * 256), 10).toString(16);
        if (uuidchar.length === 1) {
            uuidchar = "0" + uuidchar;
        }
        uuidpart += uuidchar;
    }
    return uuidpart;
}
