import React, { Component } from 'react';
import md5 from "md5";
import * as server from "../js/communication.js";
import {loggedOn} from "./App.js";
import './LoginPage.css';

class LoginPage extends Component {

  constructor(props){
    super(props);
    this.props = props;
  }

  login(e){
    e.preventDefault();
    var that = this;
    var username = document.getElementById("user").value;
    var password = md5(document.getElementById("password").value.toString());
    server.login(username, password, function(err, data){
      if(err){
        alert("Login unsuccesful.");
        return;
      }
      that.props.login(loggedOn, data.user);
    });
  }

  render() {
    return (
      <form className="loginform">
        <label>
            Username:
            <input id="user" type="text" name="username"/>
        </label>
        <label>
            Password:
            <input id="password" type="password" name="password"/>
        </label>
        <button className="loginButton" onClick={(e) => this.login(e)}>
          Login
        </button>
      </form>
    );
  }
}

export default LoginPage;
