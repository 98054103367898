import React, {Component} from 'react';
import views from "../../js/views.js";
import * as server from "../../js/communication.js";
import './CourseList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CourseList extends Component{

  constructor(props){
    super(props);
    this.props = props;
  }

  handleCourseClick(id, e){
    e.preventDefault();
    var that = this;
    server.getCourseDetails(id, function(err, courseDetails){
      if(err){
        console.log(err);
        return;
      }
      //console.log(courseDetails);
      that.props.updateView(views.Course, courseDetails);
    });
  }

  handleStatisticsClick(id, e){
    e.preventDefault();
    this.props.updateView(views.CourseStatistics, {courseId : id});
  }

  handleAttendanceClick(id, e){
    e.preventDefault();
    this.props.updateView(views.CourseAttendance, {courseId : id});
  }

  render(){
    var courses = [];
    var courseList = this.props.data;
    for (var c in courseList){
      courses.push(
        <div key={c} className="teacherCourseListItem">
          <div className="course" onClick = {this.handleCourseClick.bind(this, c)}>
          {courseList[c]}
          </div>
          <div className="buttonMenu" >
            <div onClick={this.handleStatisticsClick.bind(this, c)}><FontAwesomeIcon icon="chart-bar"/></div>
            <div onClick={this.handleAttendanceClick.bind(this, c)}><FontAwesomeIcon icon="check"/></div>
            <div onClick={() => console.log("Clicked")}><FontAwesomeIcon icon="paste"/></div>
            <div onClick={() => console.log("Clicked")}><FontAwesomeIcon icon="bars"/></div>
          </div>
        </div>);
    }

    return(
      <div className="teacherCourseList">
      {courses}
      </div>
    );
  }

}

export default CourseList;
