import React, { Component } from 'react';
import views from "../../js/views.js";
import * as server from "../../js/communication.js";

import CourseList from './CourseList.jsx';
import Course from './Course.jsx';
import HeaderBar from '../HeaderBar.jsx';
import StatisticsView from './StatisticsView.jsx';
import AttendanceView from './AttendanceView.jsx';

import './TeacherMainPage.css'
class TeacherMainPage extends Component{

  constructor(props){
    super(props);
    this.state = {
      view : null,
      viewData : null
    };

    this.switchToCourseView();
  }

  /*
  Returns to the course list view
  */
  switchToCourseView(){
    var that = this;
    server.getCourseList(function(err, result){
      if(err){
        console.log("Error getting course list!",err);
        return;
      }
      that.updateView(views.CourseList, result);
    });
  }

  /*
  Handles updating the view and passing the appropriate data
  */
  updateView(newView, viewData){
    this.setState({
      view : newView,
      viewData : viewData
    });
  }

  render(){
    var view = <div/>;
    if(this.state.view === views.CourseList){
      view = <CourseList
      data={this.state.viewData}
      updateView={this.updateView.bind(this)}/>
    }
    if(this.state.view === views.Course){
      view = <Course
      data={this.state.viewData}
      updateView={this.updateView.bind(this)}/>
    }

    if(this.state.view === views.CourseStatistics){
      view = <StatisticsView
      data={this.state.viewData}
      updateView={this.updateView.bind(this)}/>
    }

    if(this.state.view === views.CourseAttendance){
      view = <AttendanceView
      data={this.state.viewData}
      updateView={this.updateView.bind(this)}/>
    }

    return(
      <>
        <HeaderBar
        courseListView={this.switchToCourseView.bind(this)}
        updateView={this.updateView.bind(this)}
        />
        {view}
      </>
    );
  }
}

export default TeacherMainPage;
