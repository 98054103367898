import React, {Component} from 'react';

import Round from './Round.jsx';
import './Course.css';

class Course extends Component{


  render(){
    var rounds = [];
    for(var r in this.props.data.rounds){
      var round = this.props.data.rounds[r];
      rounds.push(
        <Round
        key={round['rid']}
        data={round}/>
      );
    }

    return (
      <>
      <div>{this.props.data.cname}</div>
      <div className="roundList">{rounds}</div>
      </>
    );
  }
}
export default Course;
