import * as config from '../config/config.js';

export var routes = Object.freeze({
  server : config.server,
  login : "login/",
  logout : "logout/",
  courseList : "courses/",
  courseDetails : "courseDetails/",
  updateRound : "roundUpdate/",
  courseStatistics :  "courseStatistics/",
  courseAttendance :  "attendance/",
  courseAttendanceAssigs : "attendanceAssigs/",
  attendanceUpdate : "attendanceUpdate/"
});
