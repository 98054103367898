import React, {Component} from 'react';
import LoadingIcon from '../LoadingIcon.jsx';

import './StatisticsView.css';
import * as server from '../../js/communication.js';

class StatisticsView extends Component{

  constructor(props){
    super(props);
    console.log(props.data);
    this.state = {loading : true};

    var that = this;
    server.getCourseStatistics(props.data.courseId, function(err, courseDetails){
      if(err){
        console.log(err);
        return;
      }
      that.setState({
        loading : false,
        data : courseDetails
        });
    });
  }

  getStatisticsView(){
    var items = [];
    var details = JSON.parse(this.state.data);
    var rounds = details.rounds;
    var scores = details.userScores;

    for (var i in scores){
      items.push(
        <div key ={i} className="statisticsListItem">
          <div>
          {scores[i].name}
          </div>
          <div>
          {scores[i].sum}
          </div>
        </div>);
    }
    return items
  }

  render(){
    var page = <LoadingIcon/>;
    if(!this.state.loading){
      page = this.getStatisticsView();
    }
    return(
      <>
      <div className="statisticsViewHeader" style={{top:"20px"}}>
        <div>Name</div>
        <div>Max score</div>
      </div>
      <div className="statisticsView">
      {page}
      </div>
    </>);
  }
}

export default StatisticsView;
