import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './LoadingIcon.css';

class LoadingIcon extends Component{
  render(){
    return (<FontAwesomeIcon className="rotate center" icon="sync"/>);
  }
}

export default LoadingIcon;
