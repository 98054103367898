import React, {Component} from 'react';
import DatePicker from 'react-mobile-datepicker';
import * as server from '../../js/communication.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Round.css';

class Round extends Component{

  constructor(props){
    super(props);

    var openTime = new Date(props.data.ropen);
    var closeTime = new Date(props.data.rclose);

    this.headerFormat = "YYYY/DD/MM hh:mm";

    //CSS
    this.showDetails = "round-details visible";
    this.hideDetails = "round-details hidden";

    this.state = {
      openTimeVisible : false,
      closeTimeVisible : false,
      roundVisible : props.data.rvisible,
      detailsCSS : this.hideDetails,
      openTime : openTime,
      closeTime : closeTime,
      assigsShown : false
    }

    this.dateFormat = {
      'year': {
          format: 'YYYY',
          caption: 'Year',
          step: 1,
      },
      'date': {
          format: 'D',
          caption: 'Day',
          step: 1,
      },
      'month': {
          format: 'M',
          caption: 'Mon',
          step: 1,
      },
      'hour': {
          format: 'hh',
          caption: 'Hour',
          step: 1,
      },
      'minute': {
          format: 'mm',
          caption: 'Min',
          step: 1,
      },
      'second': {
          format: 'hh',
          caption: 'Sec',
          step: 1,
      },
    };
  }

  openDateChanged = (time) => {
    var that = this;
    server.updateRound(this.props.data['rid'], time, "openDate", function(err, status){
      if(err || !status){
        console.log("Failed updating round close time.",err);
        return;
      }
      that.setState({
        openTime : time,
        openTimeVisible : false
      });

    });

  }
  closeDateChanged = (time) => {
    var that = this;
    server.updateRound(this.props.data['rid'], time, "closeDate", function(err, status){
      if(err || !status){
        console.log("Failed updating round close time.",err);
        return;
      }
      that.setState({
        closeTime : time,
        closeTimeVisible : false
      });

    });
  }

  roundVisibilityChanged = (e) => {
    var that = this;
    e.preventDefault();
    server.updateRound(this.props.data['rid'], !that.state.roundVisible, "visibility", function(err, status){
      if(err || !status){
        console.log("Failed updating round visibility.",err);
        return;
      }
      that.setState({roundVisible : !that.state.roundVisible});
    });
  }

  getRoundVisibilityButton(){
    var visible = this.state.roundVisible ?  <FontAwesomeIcon icon="check-square"/> :  <FontAwesomeIcon icon="square"/>;

    return(<div onClick={this.roundVisibilityChanged}>{visible}</div>);
  }

  getOpenDatePicker(){
    return (<div>
        <div onClick={(e) => {this.setState({openTimeVisible : true});}}>
          {this.state.openTime.toLocaleString()}
          <FontAwesomeIcon icon="calendar-alt"/>
        </div>
        <div className="datePicker" style={{
          visibility: this.state.openTimeVisible ? "visible" : "hidden"
          }}>
          <DatePicker style={{position:"sticky"}}
          value = {this.state.openTime}
          cancelText = "cancel"
          confirmText = "confirm"
          showCaption={true}
          dateConfig = {this.dateFormat}
          headerFormat = {this.headerFormat}
          onSelect = {this.openDateChanged}
          onCancel = {(e) => this.setState({openTimeVisible : false})}
          isPopup = {false}
          />

        </div>
      </div>);
  }

  getClosedDatePicker(){
    return (<div>
        <div onClick={(e) => {this.setState({closeTimeVisible : true});}}>
          {this.state.closeTime.toLocaleString()}
          <FontAwesomeIcon icon="calendar-alt"/>
        </div>
        <div
        className="datePicker"
        style={{
          visibility: this.state.closeTimeVisible ? "visible" : "hidden"
          }}>
          <DatePicker
          value = {this.state.closeTime}
          cancelText = "cancel"
          confirmText = "confirm"
          showCaption={true}
          dateConfig = {this.dateFormat}
          headerFormat = {this.headerFormat}
          onSelect = {this.closeDateChanged}
          onCancel = {() => this.setState({closeTimeVisible : false})}
          isPopup = {false}
          />
        </div>
      </div>);
  }

  switchDetailsVisiblity(e){
    e.preventDefault();
    if(this.state.detailsCSS === this.showDetails){
      this.setState({detailsCSS : this.hideDetails});
    }else{
      this.setState({detailsCSS : this.showDetails});
    }
  }

  getAssigView(){
    var assigs = [];
    for(var i in this.props.data['assigs']){
      var assig = this.props.data['assigs'][i];
      assigs.push(
        <div key={assig.aid}>
        {assig.aname} ({assig.apoints})
        </div>
      );
    }
    return assigs;
  }

  getAssigViewIcon(){
    var visible = this.state.assigsShown ?  <FontAwesomeIcon icon="angle-down"/> :  <FontAwesomeIcon icon="angle-right"/>;

    return(<div onClick={ () => this.setState({assigsShown : ! this.state.assigsShown})}>{visible}</div>);
  }

  render(){
    var round = this.props.data;
    var numOfAssigs = Object.keys(round['assigs']).length;

    return(
    <div className="roundItem" key = {round['rid']}>
      <button
        className="roundButton"
        onClick={this.switchDetailsVisiblity.bind(this)}>{round['rname']}
      </button>
      <div className={this.state.detailsCSS}>
        <div className="roundSetting">
        Opens: {this.getOpenDatePicker()}
        </div>
        <div className="roundSetting">
        Closes: {this.getClosedDatePicker()}
        </div>
        <div className="roundSetting">
        description: {round['rdesc']}
        </div>
        <div className="roundSetting">
        visible: {this.getRoundVisibilityButton()}
        </div>
        <div >
        <span className="roundSetting">Assignments: {numOfAssigs} {numOfAssigs === 0 ? "" : this.getAssigViewIcon()}</span>
          <div style= {{display : this.state.assigsShown ? "flex" : "none"}}>
          {this.getAssigView()}
          </div>
        </div>
      </div>
    </div>);
  }
}

export default Round;
