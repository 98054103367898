import $ from 'jquery';
import config from "../config/config.js";
import {routes} from "./routes.js";

  var authToken = function(){
    return sessionStorage.getItem("token")+"/";
  }
/*
updateType : [closeDate|openDate|visibility]
*/
  export var putAttendance = function(courseID, assigId, attended, callback){
    $.ajax({
    url: config.server+authToken()+routes.attendanceUpdate,
    type: 'PUT',
    data: {
      identifier : sessionStorage.getItem("VUID"),
      courseID : courseID,
      assigID : assigId,
      attended : attended
    },
    success: function(data) {
        callback(null, data);
    },
    fail: function(err){
      console.log("err",err)
      callback(err, null);
    }
    });
  }

  export var getAttendanceAssigs = function(courseID, callback){
    $.get(config.server+authToken()+routes.courseAttendanceAssigs,
    {
      identifier : sessionStorage.getItem("VUID"),
      courseID : courseID
    },
      function(data, status){
        callback(null, data);
      }).fail(function(err){
        callback(err, null);
      });
  }

  export var getCourseAttendance = function(courseID, assigID, callback){
    $.get(config.server+authToken()+routes.courseAttendance,
    {
      identifier : sessionStorage.getItem("VUID"),
      courseID : courseID,
      assigID : assigID
    },
      function(data, status){
        callback(null, data);
      }).fail(function(err){
        callback(err, null);
      });
  }

  export var getCourseStatistics = function(courseID, callback){
    $.get(config.server+authToken()+routes.courseStatistics,
    {
      identifier : sessionStorage.getItem("VUID"),
      courseID : courseID
    },
      function(data, status){
        callback(null, data);
      }).fail(function(err){
        callback(err, null);
      });
  }

  export var updateRound = function (roundID, data, updateType, callback){
    $.post(config.server+authToken()+routes.updateRound,
      {
        identifier : sessionStorage.getItem("VUID"),
        roundID : roundID,
        type : updateType,
        data : data
      },
      function(data, status){
        callback(null, data);
      }).fail(function(err){
        callback(err, null);
      });
  }

  export var getCourseDetails = function(courseId, callback){
    $.get(config.server+authToken()+routes.courseDetails,
      {
        identifier : sessionStorage.getItem("VUID"),
        courseID : courseId
      },
      function(data, status){
        callback(null, data);
      }).fail(function(err){
        callback(err, null);
      });
  }
  /*
  Retrieves the course list for the current user.
  Callback params: error, result
  */
  export var getCourseList  = function(callback){
    $.get(config.server+authToken()+routes.courseList,
    {
      identifier : sessionStorage.getItem("VUID")
    },
      function(data, status){
        callback(null, data);
      }).fail(function(err){
        callback(err, null);
      });
  }

  export var loginWithToken = function(callback){
    $.post(config.server+authToken()+routes.login,
    {
      identifier: sessionStorage.getItem("VUID")
    }, function(data, status){
        if(!data){
          callback(null);
        }else{
          callback(data.user);
        }
      }
    ).fail(function(err){
      console.log("Login with token unsuccesful; communication error",err);
      callback(null);
    });
  }

  export var login = function(username, password, callback){
    $.post(config.server+routes.login,
    {
      username: username,
      password: password,
      identifier: sessionStorage.getItem("VUID")
    }, function(data, status){
        sessionStorage.setItem("token", data.token);
        callback(null, data);
      }
    ).fail(function(err){
      alert("Login unsuccesful");
      callback(err, null);
    });
  }

  export var logout = function(callback){
    $.post(config.server+authToken()+routes.logout,
    {
      identifier: sessionStorage.getItem("VUID")
    }, function(data, status){
        callback(null, true);
      }
    ).fail(function(err){
      alert("Logout unsuccesful");
      callback(err, false);
    });
  }
