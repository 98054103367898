import React, { Component } from 'react';
import './App.css';
import LoginPage from './LoginPage.jsx';
import TeacherMainPage from './Teacher/TeacherMainPage.jsx';
import StudentMainPage from './Student/StudentMainPage.jsx';
import {getVUID} from "../js/helpers.js";
import * as server from '../js/communication.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt,
  faAngleRight, faAngleDown, faSync, faChartBar,
  faCheck, faPaste, faBars, faHome, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

  import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

export const notLogged = 0;
const loggingIn = 1;
export const loggedOn = 2;

class App extends Component {

  createFontAwesomeLibrary(){
    library.add(faCheckSquare,
      faSquare, faCalendarAlt, faAngleRight,
      faAngleDown, faSync, faChartBar, faCheck,
      faPaste, faBars, faHome, faSignOutAlt);
  }

  constructor(){
    super();
    this.state = {};
    this.createFontAwesomeLibrary();

    var tryAutoLogin = function(){
      var vuid = sessionStorage.getItem("VUID");
      if (!vuid){
        sessionStorage.setItem("VUID", getVUID());
      }

      var token = sessionStorage.getItem("token");
      if (!token){
        this.state.loginStatus = notLogged;
      }else{
        var that = this;
        this.state.loginStatus = loggingIn;
        server.loginWithToken(function(user){
          that.loginStatus(!user ? notLogged : loggedOn, user);
        });
      }
    }.bind(this);
    tryAutoLogin();
  }

  loginStatus(status, user){
    this.setState({
      loginStatus : status,
      user: user
    });
  }

  render() {
    var page;
    switch(this.state.loginStatus){
      case notLogged:
      page = <LoginPage login={(loggedOn, user) => this.loginStatus(loggedOn, user)}/>;
      break;
      case loggingIn:
      page = <div/>;
      break;
      case loggedOn:
        if(this.state.user.isTeacher === true)
          page = <TeacherMainPage user={this.state.user}/>;
        else
          page = <StudentMainPage user={this.state.user}/>;
      break;
      default:
      page = <div/>;
    }

    return (
      <div className="App">
        {page}
      </div>
    );
  }
}

export default App;
